(function () {
	'use strict';

	angular
		.module('app')
		.directive('altView', [
			'$rootScope',
			'calendarIO',
			'calendarEvents',
			'manageFilters',
			'manageMap',
			'seedcodeCalendar',
			'dataStore',
			'utilities',
			altView,
		]);

	function altView(
		$rootScope,
		calendarIO,
		calendarEvents,
		manageFilters,
		manageMap,
		seedcodeCalendar,
		dataStore,
		utilities
	) {
		const registeredMethods = {};
		let focusPinTimeout;
		seedcodeCalendar.init('altViewMethods', registeredMethods);
		return {
			restrict: 'AE',
			scope: true,
			controller: function ($scope) {
				const config = seedcodeCalendar.get('config');
				const altView = seedcodeCalendar.get('alt-view');

				$scope.enabled = altView.enabled;

				$scope.props = {
					// shared props
					getSchedulesLoaded: calendarEvents.getSchedulesLoaded,
					findTextInFields: manageFilters.findTextInFields,
					onEventClick: calendarEvents.onEventClick,
					onEventMouseOver: calendarEvents.onEventMouseOver,
					help: utilities.help,
					resize: resize,
					update: update,

					// unscheduled props
					unscheduledEnabled: config.unscheduledEnabled,
					unscheduledEventDrop: calendarEvents.unscheduledEventDrop,
					beforeEventRender: calendarEvents.beforeEventRender,
					afterAllUnscheduledRendered:
						calendarEvents.onUnscheduledAfterAllRender,
					showUnscheduledPopover:
						calendarEvents.showUnscheduledPopover,
					registerMethod: registerMethod,

					// Map Props
					mapEnabled: config.mapActivated && config.mapEnabled,
					mapApiKey: config.mapApiKey,
					getEvents: () => {
						return seedcodeCalendar
							.get('element')
							.fullCalendar('clientEvents');
					},
					mapManager: manageMap,
					setReportingCallback: calendarEvents.setReportingCallback,
					isEventShown: calendarEvents.isEventShown,
					hasCalendarRendered: renderedPromise(),
					focusEvent: (event) => {
						const {type, id, parentElementQuery, forceEqualSides} =
							calendarIO.getFocusEvent();
						if (id === event._id && type === 'map') {
							if (focusPinTimeout) {
								window.clearTimeout(focusPinTimeout);
							}
							focusPinTimeout = window.setTimeout(() => {
								calendarIO.focusEvent(
									event,
									type,
									parentElementQuery,
									forceEqualSides
								);
							}, 50);
						}
					},
				};

				function renderedPromise() {
					return new Promise((resolve, reject) => {
						if (seedcodeCalendar.get('initCalendar')) {
							$scope.calendarRendered = true;
							resolve();
						} else {
							const watchCalendarRendered = $scope.$on(
								'initCalendar',
								() => {
									$scope.calendarRendered = true;
									// Stop watching
									watchCalendarRendered();
									resolve();
								}
							);
						}
					});
				}

				// function hasCalendarRendered() {
				// 	return new Promise((resolve, reject) => {
				// 		let hasRendered = seedcodeCalendar.get('initCalendar');
				// 		if (hasRendered) {
				// 			resolve();
				// 			return;
				// 		} else {
				// 			watchCalendarRendered = $scope.$on(
				// 				'initCalendar',
				// 				() => {
				// 					// Stop watching
				// 					watchCalendarRendered();
				// 					resolve();
				// 				}
				// 			);
				// 		}
				// 	});
				// }

				function update(altView) {
					const altViewObj = seedcodeCalendar.get('alt-view');
					for (const property in altView) {
						altViewObj[property] = altView[property];
					}
					// $rootScope.$broadcast('alt-view', altView);
					dataStore.saveState('showAltView', altViewObj.show);
					dataStore.saveState('altViewType', altViewObj.type);
					dataStore.saveState('altViewWidth', altViewObj.width);
				}

				function resize(preventRender) {
					$rootScope.$broadcast('resize');
					if (!preventRender) {
						utilities.resizeCalendar(0);
					}
				}

				function registerMethod(method, func) {
					// Registered methods are:
					// updateUnscheduledFilter
					registeredMethods[method] = func;
				}
			},
			templateUrl: 'app/alt-view/container.html',
		};
	}
})();
